<template>
	<el-upload
		action=""
		list-type="picture-card"
		accept=".jpg,.jpeg,.png,.gif"
		multiple
		:file-list="fileList"
		:on-remove="handleRemove"
		:before-upload="handleBeforeUpload"
		:http-request="uploadURL"
		:on-exceed="handleExceed"
		:class="[{ hide: fileList.length >= maxCount || disabled }, { hideDel: disabled }]"
	>
		<i class="el-icon-plus" />
	</el-upload>
</template>
<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	name: 'MultiUpload',
	props: {
		// 图片属性数组
		value: {
			type: Array,
			default: () => [],
		}, // 最大上传图片数量
		maxCount: {
			type: Number,
			default: 6,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isShowDetail: {
			type: Boolean,
			default: false,
		},
		modulePath: {
			type: String,
			default: 'public',
		},
		operator: {
			type: String,
			default: 'zhangmt',
		},
	},
	data() {
		return {
			fileList: [],
			images: [],
			dialogVisible: false,
			dialogImageUrl: null,
			dialogImageName: '',
			num: 0,
			fileNum: 0,
			// loading: false,
		};
	},
	created() {
		// 上传组件显示列表
		this.fileList = this.value; // 图片保存数
	},
	watch: {
		value(val) {
			this.fileList = val;
		},
	},
	methods: {
		// 自定义上传
		uploadURL(file) {
			let fileName = 'bernieri/' + `${this.operator}/${this.modulePath}/${getFileNameUUID()}` + '.png';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.images.push({
							title: res.name,
							url: path,
						});
						this.num++;
						if (this.num === this.fileNum) {
							this.num = 0;
							this.fileNum = 0;
							this.fileList.push(...this.images);
							this.$emit('uploadImg', this.fileList);
							this.images = [];
						}
					}
				});
		},
		handleRemove(file, fileList) {
			// if (file.id) {
			// 	this.$emit('deleteFile', file);
			// }
			const list = JSON.parse(JSON.stringify(fileList));
			this.fileList = list.map((item) => ({
				...item,
				title: item.title,
				url: item.url,
			}));
			this.$emit('uploadImg', this.fileList);
		},
		handleBeforeUpload() {
			this.fileNum++;
		},
		handleExceed() {
			// this.$message({
			// 	message: '最多只能上传' + this.maxCount + '张图片',
			// 	type: 'warning',
			// 	duration: 1000,
			// });
		},
	},
};
</script>
<style scoped lang="scss">
.hide {
	/deep/ .el-upload--picture-card {
		display: none !important;
	}
}
.hideDel {
	/deep/ .el-upload-list--picture-card .el-upload-list__item-actions {
		display: none !important;
	}
}
</style>


